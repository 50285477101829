const REACT_APP_NAME = window.REACT_APP_NAME || 'pippo';
const REACT_APP_LOGO_FILENAME = window.REACT_APP_LOGO_FILENAME || 'pippo';
const REACT_APP_BEDROCK_API_PROTOCOL = window.REACT_APP_BEDROCK_API_PROTOCOL || 'pippo';
const REACT_APP_BEDROCK_API_URL = window.REACT_APP_BEDROCK_API_URL || 'pippo';
const REACT_APP_BEDROCK_API_PORT = window.REACT_APP_BEDROCK_API_PORT || 'pippo';
const REACT_APP_WEBSOCKET_PROTOCOL = window.REACT_APP_WEBSOCKET_PROTOCOL || 'pippo';
const REACT_APP_WEBSOCKET_URL = window.REACT_APP_WEBSOCKET_URL || 'pippo';
const REACT_APP_WEBSOCKET_URI = window.REACT_APP_WEBSOCKET_URI || 'pippo';
const REACT_APP_WEBSOCKET_PORT = window.REACT_APP_WEBSOCKET_PORT || 'pippo';
const REACT_APP_Q_PLACEHOLDER = window.REACT_APP_Q_PLACEHOLDER || 'pippo';
const REACT_APP_RESET_BUTTON_LABEL = window.REACT_APP_RESET_BUTTON_LABEL || 'pippo';
const REACT_APP_LOAD_PATIENT_BUTTON_LABEL = window.REACT_APP_LOAD_PATIENT_BUTTON_LABEL || 'pippo';
const REACT_APP_LOAD_CONTEXT_BUTTON_LABEL = window.REACT_APP_LOAD_CONTEXT_BUTTON_LABEL || 'pippo';
const REACT_APP_KEYCLOAK_REALM = window.REACT_APP_KEYCLOAK_REALM || 'pippo';
const REACT_APP_KEYCLOAK_URL = window.REACT_APP_KEYCLOAK_URL || 'pippo';
const REACT_APP_KEYCLOAK_CLIENT_ID = window.REACT_APP_KEYCLOAK_CLIENT_ID || 'pippo';
const REACT_APP_KEYCLOAK_LOGOUT_URL = window.REACT_APP_KEYCLOAK_LOGOUT_URL || 'pippo';

export {
  REACT_APP_NAME,
  REACT_APP_LOGO_FILENAME,
  
  REACT_APP_BEDROCK_API_PROTOCOL,
  REACT_APP_BEDROCK_API_URL,
  REACT_APP_BEDROCK_API_PORT,
  
  REACT_APP_WEBSOCKET_PROTOCOL,
  REACT_APP_WEBSOCKET_URL,
  REACT_APP_WEBSOCKET_URI,
  REACT_APP_WEBSOCKET_PORT,
  
  REACT_APP_Q_PLACEHOLDER,
  REACT_APP_RESET_BUTTON_LABEL,
  REACT_APP_LOAD_PATIENT_BUTTON_LABEL,
  REACT_APP_LOAD_CONTEXT_BUTTON_LABEL,
  
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_LOGOUT_URL
}
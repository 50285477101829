import React, { useState, useEffect } from 'react';
import './App.css';
import WebSocketComponent from './WebSocketComponent';
import { Box, Button, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import addicon from './images/documents.svg';

import getKeycloakInstance from './KeycloakInstance';

import packageJson from '../package.json'; // Adjust the path as necessary

import {
  REACT_APP_NAME,
  REACT_APP_LOGO_FILENAME,

  REACT_APP_BEDROCK_API_PROTOCOL,
  REACT_APP_BEDROCK_API_URL,
  REACT_APP_BEDROCK_API_PORT,

  REACT_APP_Q_PLACEHOLDER,
  REACT_APP_RESET_BUTTON_LABEL,
  REACT_APP_LOAD_PATIENT_BUTTON_LABEL,
  REACT_APP_LOAD_CONTEXT_BUTTON_LABEL,

  REACT_APP_KEYCLOAK_LOGOUT_URL
} from './config.js';


const FileDropArea = ({ onFilesAdded }) => {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const files = Array.from(e.dataTransfer.files).filter(file =>
      file.type === 'application/pdf' || file.name.endsWith('.docx') || file.type === 'text/plain'
      || file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif'
    );
    if (files.length > 0) {
      onFilesAdded(files);
    }
  };

  return (
    <span>
      {dragOver &&
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{
            height: '400px',
            width: '98vw',
            position: 'fixed',
            top: '250px',
            left: '1vw',
            border: '1px dashed #62b8fe',
            borderRadius: '10px',
            textAlign: 'center',
            color: '#62b8fe',
            backgroundColor: 'rgba(15, 15, 28, 0.8)',
          }}
        >
          <p>Drop PDF, DOCX, TXT or IMAGE files here.<br />In case of multiple file uploads, you cannot mix IMAGES with PDF/DOCX/TEXT files<br /><br /><br /><br /><img width={'100px'} src={addicon} alt=''></img></p>
        </div>
      };
      {!dragOver &&
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{
            height: '50px',
            width: '50px',
            position: 'fixed',
            top: '250px',
            left: '20px',
            border: '1px solid #62b8fe',
            borderRadius: '10px',
            textAlign: 'center',
            color: '#62b8fe',
          }}
        >
          <img style={{ 'padding': '12px' }} width={'25px'} src={addicon} alt=''></img>
        </div>
      };
    </span>
  );
};

const handleLogout = () => {
  const kc = getKeycloakInstance();
  kc.logout({ redirectUri: REACT_APP_KEYCLOAK_LOGOUT_URL });
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {

  const [isInitialized, setIsInitialized] = useState(false);
  const [givenName, setGivenName] = useState('');
  const [version, setVersion] = useState('');
  const [inputText, setInputText] = useState('');
  const [appVersionFE, setAppVersionFE] = useState('-');
  const [appVersionBE, setAppVersionBE] = useState('-');

  const uploadFiles = (files) => {
    const kc = getKeycloakInstance();

    const formData = new FormData();
    let counter = 0;
    files.forEach(file => {
      formData.append(`file-${counter}`, file);
      counter += 1;
    });

    fetch(`${protocol}://${url}:${port}/api/upload/files`, {
      method: 'POST',
      body: formData,
      headers: {
        'User-Id': kc.tokenParsed.preferred_username
      },
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error uploading file:', error));
  };

  useEffect(() => {
    const kc = getKeycloakInstance();
    const initialize = async () => {
      try {
        await kc.init({ onLoad: 'login-required', checkLoginIframe: true }).then((authenticated) => {
          if (authenticated) {
            kc.onTokenExpired = () => {
              console.log('Token expired');
            };
          } else {
            console.log('User is not authenticated');
          }
        }, (error) => { console.error('Error initializing Keycloak:', error) }
        );
        setIsInitialized(true); // Update state to indicate completion
      } catch (error) {
        console.error('Initialization failed:', error);
      }
    };

    initialize();
  }, []); // This effect runs only once on component mount

  useEffect(() => {
    if (isInitialized) {
      const kc = getKeycloakInstance();
      setGivenName(kc.tokenParsed.given_name);
    }
  }, [isInitialized]); // This effect runs when `isInitialized` changes

  const images = require.context('./images', true);
  const logo = images(`./${REACT_APP_LOGO_FILENAME}`);

  const protocol = REACT_APP_BEDROCK_API_PROTOCOL;
  const url = REACT_APP_BEDROCK_API_URL;
  const port = REACT_APP_BEDROCK_API_PORT;

  useEffect(() => {
    async function fetchVersion() {
      try {
        const response = await fetch(`${REACT_APP_BEDROCK_API_PROTOCOL}://${REACT_APP_BEDROCK_API_URL}:${REACT_APP_BEDROCK_API_PORT}/api/info/ai-model`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setVersion(data.aiModel);
      } catch (error) {
        console.error('Error sending text to API:', error);
      }
      try {
        const response = await fetch(`${REACT_APP_BEDROCK_API_PROTOCOL}://${REACT_APP_BEDROCK_API_URL}:${REACT_APP_BEDROCK_API_PORT}/api/info/app-version`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setAppVersionBE(data.appVersion);
        setAppVersionFE(packageJson.version);
      } catch (error) {
        console.error('Error sending text to API:', error);
      }  
    }

    fetchVersion();
  }, []);

  const sendTextToAPI = async () => {
    const kc = getKeycloakInstance();
    try {
      setInputText('');
      const response = await fetch(`${protocol}://${url}:${port}/api/chat/query`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'User-Id': kc.tokenParsed.preferred_username
        },
        body: JSON.stringify({ 'query': inputText }),
      });
      const data = await response.json();
      console.log(data); // Handle the response data
    } catch (error) {
      console.error('Error sending text to API:', error);
    }
  };

  const sendStaticPayloadToAPI = async () => {
    const kc = getKeycloakInstance();
    try {
      const response = await fetch(`${protocol}://${url}:${port}/api/chat/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'User-Id': kc.tokenParsed.preferred_username
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      console.log(data); // Handle the response data
    } catch (error) {
      console.error('Error sending static payload to API:', error);
    }
  };

  const loadDocumentInContext = async () => {
    const kc = getKeycloakInstance();

    try {
      const response = await fetch(`${protocol}://${url}:${port}/api/load/all_documents_in_context/regolamento`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'User-Id': kc.tokenParsed.preferred_username
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      console.log(data); // Handle the response data
    } catch (error) {
      console.error('Error sending request to load document in context to API:', error);
    }
  };

  const loadDocumentsForPatient = async () => {
    const kc = getKeycloakInstance();

    setInputText('');
    try {
      const response = await fetch(`${protocol}://${url}:${port}/api/load/all_documents_in_folder/docx/${inputText.trim()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'User-Id': kc.tokenParsed.preferred_username
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      console.log(data); // Handle the response data
    } catch (error) {
      console.error('Error sending request to load document in context to API:', error);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>

      <div>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <div className="input-box">
          <Box
            sx={{
              '& > :not(style)': { m: 1 }, // Apply margin to all children except style elements
              display: 'flex', // Use flexbox for layout
              flexDirection: 'column', // Stack children vertically
            }}
          >
            <Box className="input-box-field"
              sx={{
                display: 'flex', // Use flexbox for layout
                flexDirection: 'row', // Place children in a row
                alignItems: 'center', // Align items vertically in the center
              }}
            >
              <TextField
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder={REACT_APP_Q_PLACEHOLDER}
                variant='standard'
                fullWidth
                multiline
                rows={2}
                style={{ paddingRight: '30px' }}
                onKeyDown={(e) => {
                  if ((e.key === 'Enter' && e.ctrlKey) || e.key === 'Enter') {
                    e.preventDefault(); // Prevent the default action to avoid submitting the form or any other unwanted behavior
                    sendTextToAPI(); // Call the function to send the text to the API
                  }
                }}
              />
              <Button variant='outlined' onClick={sendTextToAPI}>Send</Button>
            </Box>
            <Box className="input-box-field"
              sx={{
                display: 'flex', // Use flexbox for layout
                flexDirection: 'row', // Place children in a row
                alignItems: 'center', // Align items vertically in the center
                justifyContent: 'center', // Distribute space around items
              }}
            >
              <Box
                sx={{
                  display: 'flex', // Use flexbox for layout
                  flexGrow: 1, // Allow the box to expand as much as possible
                  justifyContent: 'end', // Center items horizontally
                  alignItems: 'end', // Align items vertically in the center
                  gap: '30px'
                }}
              >

                <Button variant='outlined' onClick={sendStaticPayloadToAPI}>{REACT_APP_RESET_BUTTON_LABEL}</Button>
                {REACT_APP_NAME === 'medas' && <Button variant='outlined' onClick={loadDocumentsForPatient}>{REACT_APP_LOAD_PATIENT_BUTTON_LABEL}</Button>}
                {REACT_APP_NAME === 'medas' && <Button variant='outlined' onClick={loadDocumentInContext}>{REACT_APP_LOAD_CONTEXT_BUTTON_LABEL}</Button>}
                <Button variant='outlined' onClick={handleLogout}>logout - {givenName}</Button> {/* Logout button */}
              </Box>
              <Box
                sx={{
                  display: 'flex', // Use flexbox for layout
                  flexGrow: 1, // Allow the box to expand as much as possible
                  justifyContent: 'end', // Center items horizontally
                  alignItems: 'end', // Align items vertically in the center
                }}
              >
                <Typography variant='body2' color='textPrimary'>AI Model: <span style={{ color: '#62b8fe' }}><b>Claude3 [ {version} ]</b></span></Typography>
              </Box>
            </Box>
          </Box>
        </div>
        <footer className="footer">
          {appVersionFE} <span style={{color: '#ff0000'}}>|</span> <span style={{color: '#00ff00'}}>{appVersionBE}</span>
        </footer>
        <div className="App-body">
          <FileDropArea onFilesAdded={uploadFiles} />
          <WebSocketComponent />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;

import React, { useEffect, useState, useRef } from 'react';
import loading from './images/loading.gif';
import getKeycloakInstance from './KeycloakInstance';
import ReconnectingWebSocket from 'reconnecting-websocket';

import {
  REACT_APP_WEBSOCKET_PROTOCOL,
  REACT_APP_WEBSOCKET_URL,
  REACT_APP_WEBSOCKET_URI,
  REACT_APP_WEBSOCKET_PORT,
  } from './config.js';

const WebSocketComponent = () => {
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [newMessage, setNewMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stopScrollToBottomEffect, setStopScrollToBottomEffect] = useState(true);
  const currentIndex = useRef(0);
  const speed = 5;

  const typeWriter = useRef(null);

  useEffect(() => {
    if (stopScrollToBottomEffect) {
      return;
    }

    const interval = setInterval(() => {
      scrollToBottom();
    }, 200); // 1000 milliseconds = 1 second

    // Clear interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [stopScrollToBottomEffect]); // Empty dependency array means this effect runs once on mount and clean up on unmount

  useEffect(() => {
    const kc = getKeycloakInstance();
    const checkTokenParsed = setInterval(() => {

      if (kc.tokenParsed && kc.tokenParsed.preferred_username) {
        const url = `${REACT_APP_WEBSOCKET_PROTOCOL}://${REACT_APP_WEBSOCKET_URL}:${REACT_APP_WEBSOCKET_PORT}/${REACT_APP_WEBSOCKET_URI}/${kc.tokenParsed.preferred_username}`;
        console.log('Connecting to the websocket: ' + url);
        const wsClient = new ReconnectingWebSocket(url);

        wsClient.onopen = () => {
          console.log('WebSocket Client Connected');
        };

        wsClient.onclose = () => {
          console.log('WebSocket Client Disconnected');
        };

        wsClient.onmessage = (message) => {
          if (message.data === 'loading') {
            setIsLoading(true);
            setStopScrollToBottomEffect(false);
          } else if (message.data === 'reset') {
            setReceivedMessages([]);
            setCurrentMessage('');
            setIsLoading(false);
            setStopScrollToBottomEffect(true);
          }
          else {
            // setNewMessage(message.data.replace(/\n/g, '<br>'));
            setNewMessage(message.data);
            setIsLoading(false);
          }
        };

        clearInterval(checkTokenParsed);
        return () => {
          wsClient.close();
        };
        
      }

    }, 100);
  }, []);

  // const handleSendMessage = () => {
  //   if (client) {
  //     client.sendMessage(message);
  //     setMessage('');
  //   }
  // };

  useEffect(() => {
    if (newMessage) {
      var currentReceivedMessage = newMessage;
      currentIndex.current = 0;
      typeWriter.current = setInterval(() => {
        if (currentIndex.current < currentReceivedMessage.length) {
          setStopScrollToBottomEffect(false);
          setCurrentMessage(prev => prev + currentReceivedMessage[currentIndex.current - 1]);
          currentIndex.current++;
        } else {
          clearInterval(typeWriter.current);
          setStopScrollToBottomEffect(true);
          if (newMessage) {
            setReceivedMessages((prevMessages) => [...prevMessages, newMessage]);
            setNewMessage(null);
            setCurrentMessage('');
          }
        }
      }, speed);
    }
    return () => clearInterval(typeWriter.current);
  }, [newMessage]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto', inline: 'end' })
    // console.log('Scrolling to bottom');
  }

  return (
    <div>
      {/* <h1>MedGaiA</h1> */}
      <div className='App-body'>
        {receivedMessages.map((msg, index) => (
          msg.startsWith('__question__') ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} key={`question-${index}`}>
              <p className='request-box' key={index} dangerouslySetInnerHTML={{ __html: msg.replace('__question__ ', '') }} />
            </div>
          ) : (
            <div key={`question-${index}`}>
              <p className='response-box' key={index} dangerouslySetInnerHTML={{ __html: msg }} />
            </div>
          )
        ))}
      </div>
      <div className='App-body, App-body-typewriter'>
        <p dangerouslySetInnerHTML={{ __html: currentMessage.replace('__question__ ', '') }} ></p>
        {/* <p ref={messagesEndRef}>{currentMessage}</p> */}
      </div>
      {isLoading && <div style={{ textAligb: 'center' }} ><img src={loading} className='loading' alt="loading" /></div>}
      <div ref={messagesEndRef} ></div>
    </div>
  );
};

export default WebSocketComponent;